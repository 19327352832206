<template lang="html">
  <GradientBackLayout class="CustomLabsPageV2"
  :title="$filters.translate('labs_management2').toUpperCase()"
  :shadowText="$filters.translate('labs_management2')">
    <BoxShadow class="CustomLabsPageV2--Box">
      <div class="CustomLabsPageV2--Top">
        <div class="CustomLabsPageV2--Info">
          <h1>{{$t('labs_management2')}}</h1>
          <p>{{$t('labs_add_remove')}}</p>
        </div>
        <div class="CustomLabsPageV2--Form">
          <div class="">
            <label for="Labs">Labs</label>
            <input type="number" name="Labs" :placeholder="$t('amount')" v-model="pointsNum">
          </div>
          <div class="">
            <label for="Concept">{{$t('labs_action_concept')}}</label>
            <textarea name="Concept" rows="8" cols="80" :placeholder="$t('labs_action_explanation')" v-model="concept"></textarea>
          </div>
          <div class="CustomLabsPageV2--Actions">
            <CommunityButton :disabled="disableButtons() || isLoading" v-on:click="asignLabs('+')">{{$t('add')}}</CommunityButton>
            <CommunityButton :disabled="disableButtons() || isLoading" v-on:click="asignLabs('-')">{{$t('remove')}}</CommunityButton>
          </div>
        </div>
      </div>
      <Separator />
      <div class="CustomLabsPageV2--Participants">
        <CommunityUserAssignment
          ref="userAssingment"
          class="UserAssignment"
          :members="members"
          :tags="tags"
          :hideGamification="communityInfo.hideGamification"
          v-on:filter-all="fetchMembers($event,'filtered')"
          v-on:filter-added="fetchMembers($event,'filteredAdded')"/>
      </div>
    </BoxShadow>
  </GradientBackLayout>
</template>

<script>
import Swal from 'sweetalert2'

import { mapGetters, mapMutations  } from 'vuex';
import {
  COMMUNITY_FETCH_MEMBERS,
  COMMUNITY_SET_MEMBERS_LABS
} from '@/store/community/members.module';
import {COMMUNITY_FETCH_TAGS} from '@/store/community/tags.module'

import store from '@/store';
import Tag from '~/components/Tag';
import CommunityButton from '@/components/CommunityButton';
import SearchBar from '~/components/SearchBar/SearchBar.vue';
import GradientBackLayout from '@/layouts/GradientBackLayout'
import BoxShadow from '@/components/BoxShadow.vue'
import Separator from '@/components/Separator'
import CommunityUserAssignment from '@/components/CommunityUserAssignment.vue'
export default {
  name: 'CustomLabsPage',

  components:{
    Tag,
    CommunityButton,
    SearchBar,
    GradientBackLayout,
    BoxShadow,
    Separator,
    CommunityUserAssignment
  },

  computed: {
    ...mapGetters([
      'communityMembers',
      'communityTags',
      'communityMembersCount',
      'communityInfo',
    ]),
  },

  data() {
    return {
      concept:null,
      pointsNum:'',
      tags:[],
      members:{
        all:[],
        filtered:[],
        filteredAdded:[],
        added:[]
      },
      isLoading: false,
      currentRequest: null
    }
  },
  methods: {
    disableButtons(){
      const reg = /^\d+$/
      let disable = false
      if(this.members.added.length == 0) disable = true
      if(this.concept == null || this.concept == "") disable = true
      if(this.pointsNum == null || this.pointsNum < 1 || !reg.test(this.pointsNum)) disable = true
      return disable
    },
    async asignLabs(operator){
      let points = parseInt(operator+this.pointsNum)
      this.isLoading = true
      await store.dispatch(COMMUNITY_SET_MEMBERS_LABS,{
        userIds:this.members.added,
        concept: this.concept,
        pointsNum: points,
      })
      .then((data) => {
        if(operator == "+"){
          Swal.fire({
            text:this.$t('labs_correctly_added'),
            icon:"success",
            customClass:{popup:"swal-customSuccess"},
          })
        }
        else {
          Swal.fire({
            text:this.$t('labs_correctly_removed'),
            icon:"success",
            customClass:{popup:"swal-customSuccess"},
          })
        }
        this.isLoading = false
        this.$refs.userAssingment.deleteAllMembers()
        this.fetchMembers(null,null)
      })
    },
    async fetchMembers(filter,array){
      const request = store.dispatch(COMMUNITY_FETCH_MEMBERS,{
        notSetCommunityMembers:true,
        order:'recent',
        nickname:filter!=null ?filter.nickname:undefined,
        filter:filter!=null ?filter.filter:undefined,
      });

      this.currentRequest = request;
      const data = await request;
      if (this.currentRequest !== request) return;

      if(filter != null) this.members[array] = data.communityMembers.map(function(m){return m.identifier});
      else this.members.all = data.communityMembers
    },
  },
  async mounted(){
    await store.dispatch(COMMUNITY_FETCH_TAGS)
    .then((data) => {
      if(data.publicTags == undefined && data.tags == undefined) this.tags = []
      else if(data.publicTags == undefined) this.tags = data.tags
      else if(data.tags == undefined) this.tags = data.publicTags
      else{
        this.tags = data.publicTags.concat(data.tags)
      }
    })
    await this.fetchMembers(null, null)
  },

}
</script>
<style lang="scss">

</style>
<style scoped lang="scss">
.CustomLabsPageV2{
  &--Top{
    display: grid;
    grid-template-columns: 25% auto;
    grid-gap: 80px;
  }
  &--Info{
    h1{
      font-size: 29px;
      letter-spacing: 0.01em;
      color: #3E3E3E;
    }
    p{
      margin: 20px 0;
      font-size: 18px;
      color: #747474;
    }
  }
  &--Box{
    padding: 50px;
    flex-direction: column;
  }
  &--Form{
    div{
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }
    label{
      font-weight: bold;
      font-size: 14px;
      text-transform: uppercase;
    }
    input,textarea{
      background: #f4f4f4;
      border: 1px solid #e4e4e4;
      font-size: 13px;
      padding: .5rem 1rem;
      width: 100%;
      font-family: Roboto condensed;
      outline: none;
    }
  }
  &--Actions{
    flex-direction: row!important;
    button{
      margin-right: 20px;
      &:first-child{
        background: var(--accent-color)!important;
      }
      &:last-child{
        background: linear-gradient(255.48deg, #820509 10.29%, #BE0007 89.71%) !important;
      }
    }
  }
}
</style>
